// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-work-goldivox-js": () => import("./../../../src/pages/work/goldivox.js" /* webpackChunkName: "component---src-pages-work-goldivox-js" */),
  "component---src-pages-work-open-source-js": () => import("./../../../src/pages/work/open-source.js" /* webpackChunkName: "component---src-pages-work-open-source-js" */),
  "component---src-pages-work-stunners-js": () => import("./../../../src/pages/work/stunners.js" /* webpackChunkName: "component---src-pages-work-stunners-js" */)
}

